<template>
  <v-row no-gutters>
    <v-col>
      <v-row align="center" justify="center">
        <template v-if="showSuccessMessage">
          <v-col cols="auto">
            <v-sheet color="" class="text-center">
              <h1 class="mb-10">Success</h1>
              <p class="grey--text mb-16">Congratulations!<br/> Now you able to</p>
              <v-btn color="success" x-large to="/account/login">
                <v-icon large class="mr-5">mdi-check-circle</v-icon>
                Go to dashboard
              </v-btn>
            </v-sheet>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="8" xl="4" md="6" sm="8">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <v-form class="mt-5" @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                  <v-text-field
                      label="Email"
                      name="email"
                      v-model="form.email"
                      prepend-icon="mdi-email"
                      type="email"
                      :error-messages="errors"
                      outlined
                      dense />
                </ValidationProvider>
                <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                  <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      v-model="form.password"
                      prepend-icon="mdi-lock"
                      type="password"
                      :error-messages="errors"
                      outlined
                      dense />
                </ValidationProvider>

                <ValidationProvider name="password2" rules="required" v-slot="{ errors }">
                  <v-text-field
                      id="password2"
                      label="Confirm password"
                      name="password2"
                      v-model="form.password2"
                      prepend-icon="mdi-lock"
                      type="password"
                      :error-messages="errors"
                      outlined
                      dense/>
                </ValidationProvider>

                <div class="d-flex justify-end">
                  <v-btn color="primary" type="submit">Register</v-btn>
                </div>
              </v-form>
            </ValidationObserver>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
import User from "@/models/User";

export default {
  name: 'RegisterPage',

  data: () => ({
    form: {
      email: '',
      password: '',
      password2: '',
    },
    showSuccessMessage: false,
    drawer: false,
  }),

  methods: {
    async onSubmit() {

      if (this.form.password !== this.form.password2) {
        this.$refs.form.setErrors({
          password2: "Passwords don't match"
        })
        return
      }
      try {
        const response = await User.api().register({
          email: this.form.email,
          password: this.form.password
        })

        if (response.response.statusText === 'Created') {
          this.showSuccessMessage = true;
        }
      } catch (error) {
        if (error.response?.status === 400) {
          this.$refs.form.setErrors(error.response.data)
        } else {
          throw error
        }
      }
    },
  },

}
</script>
