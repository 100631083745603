var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.showSuccessMessage)?[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"color":""}},[_c('h1',{staticClass:"mb-10"},[_vm._v("Success")]),_c('p',{staticClass:"grey--text mb-16"},[_vm._v("Congratulations!"),_c('br'),_vm._v(" Now you able to")]),_c('v-btn',{attrs:{"color":"success","x-large":"","to":"/account/login"}},[_c('v-icon',{staticClass:"mr-5",attrs:{"large":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Go to dashboard ")],1)],1)],1)]:[_c('v-col',{attrs:{"cols":"8","xl":"4","md":"6","sm":"8"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","name":"email","prepend-icon":"mdi-email","type":"email","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","label":"Password","name":"password","prepend-icon":"mdi-lock","type":"password","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password2","label":"Confirm password","name":"password2","prepend-icon":"mdi-lock","type":"password","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Register")])],1)],1)]}}])})],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }